import Head from 'next/head';
import Script from 'next/script';
import type { AppProps } from 'next/app';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import 'react-datepicker/dist/react-datepicker.css';
import 'typeface-barlow';

import { Providers } from '@/context';
import '@/styles/styles.scss';

const promise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY as string);

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Script
        id="gtag-init"
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />
      <Script
        id="gtag-init-adwords"
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ADWORDS}`}
      />
      <Script id="gtag-track" strategy="lazyOnload">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}');
          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ADWORDS}');
        `}
      </Script>
      <Script
        id="plaid-init"
        strategy="lazyOnload"
        src="https://cdn.plaid.com/link/v2/stable/link-initialize.js"
      />
      <Script
        defer
        id="share-sale"
        strategy="lazyOnload"
        src="https://www.dwin1.com/45247.js"
      />
      <Script id="reddit-pixel" strategy="lazyOnload">
        {`
          !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','t2_ubs7di73', {"optOut":false,"useDecimalCurrencyValues":true,"aaid":"${process.env.NEXT_PUBLIC_GOOGLE_ADWORDS}"});rdt('track', 'PageVisit');
        `}
      </Script>
      <Script id="fb-pixel">
        {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', ${process.env.NEXT_PUBLIC_FB_PIXEL});
        `}
      </Script>
      <Providers>
        <Head>
          <title>Buy Insulation Direct - Hempitecture</title>
          <link rel="shortcut icon" href="/favicon.ico" />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <meta
            name="google-site-verification"
            content="XEjwcxurG8IWrQm4AJ5OmARKVxTqBeiFOkDGhWZYBhU"
          />
          <noscript>
            <img
              height="1"
              width="1"
              style={{ display: 'none' }}
              src={`https://www.facebook.com/tr?id=${process.env.NEXT_PUBLIC_FB_PIXEL}&ev=PageView&noscript=1`}
              alt="fb pixel"
            />
          </noscript>
        </Head>
        <Elements stripe={promise}>
          <Component {...pageProps} />
        </Elements>
      </Providers>
    </>
  );
}
